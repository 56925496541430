import React, { useEffect, useState } from 'react';
import calendar from '../../assets/images/webinar/calendar.svg';
import user from '../../assets/images/webinar/user-logo.svg';
import email from '../../assets/images/webinar/email-logo.svg';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import loader from '../../assets/images/loader.svg';

interface FormProps {
  className?: string;
  isHorizontal?: boolean;
}

type FormData = {
  firstName: string;
  email: string;
};

type FormStatus = 'idle' | 'pending' | 'resolved' | 'rejected';

const url = 'https://api.flowyour.money/u/register-activecampaign-user';

const Form = ({ className, isHorizontal }: FormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>();
  const [status, setStatus] = useState<FormStatus>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      clearErrors('firstName');
      clearErrors('email');
    }, 3000);
  }, [errors]);

  const onSubmit = async (data: FormData) => {
    setStatus('pending');
    clearErrors('firstName');
    clearErrors('email');
    const params = new URLSearchParams();
    params.append('firstName', data.firstName);
    params.append('email', data.email);

    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      await axios.post(url, params, config);
      reset({
        firstName: '',
        email: '',
      });
      setStatus('resolved');
      location.href = 'https://flowyourmoney.typeform.com/to/fDtdRMeA';
    } catch (error) {
      setStatus('rejected');
      if (axios.isAxiosError(error)) {
        // Thanks to the type guard, TypeScript knows know what "error" is
        setErrorMessage(
          error.response?.status === 422
            ? 'Dit email address bestaat al.'
            : 'Er is iets fout gegaan. Probeer het later nog een keer.'
        );
      }
    } finally {
      setTimeout(() => {
        setStatus('idle');
        setErrorMessage('');
      }, 5000);
    }
  };

  return (
    <div className={`${className} form ${isHorizontal ? 'form--horizontal' : ''}`}>
      {!isHorizontal && (
        <div className="form__header">
          <img src={calendar} className="form__image" width="26px" alt="calendar" />
          <span>1 december 2021, om 19:30 uur</span>
        </div>
      )}
      <div className="form__body">
        <div className="form__title">
          {isHorizontal
            ? `Registreer je nu om te leren hoe je Profit First automatiseert met Flow`
            : `Geef je gratis op voor deze live masterclass met de founder van Flow en de oprichter van Profit First Professionals! Plaatsen zijn beperkt.
            `}
        </div>
        {(errors?.firstName || errors?.email) && (
          <div className="form__alert form__alert--error">Vul je naam en email adres in</div>
        )}
        {status === 'rejected' && (
          <div className="form__alert form__alert--error">{errorMessage}</div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="form__wrapper">
          <div className="form__group">
            <img src={user} alt="user" className="form__icon" />
            <input
              {...register('firstName', {
                required: true,
              })}
              type="text"
              className="form__control"
              placeholder="Voornaam"
            />
          </div>
          <div className="form__group">
            <img src={email} alt="email" className="form__icon form__icon--email" />
            <input
              {...register('email', {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+$/,
              })}
              type="text"
              className="form__control"
              placeholder="Email"
            />
          </div>
        </form>
        <div className="form__checkbox checkbox">
          <input
            id="checkMark1"
            className="checkbox__input"
            type="checkbox"
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
          />
          <label htmlFor="checkMark1" className="checkbox__label">
            Ja ik geef toestemming om emails van Flow te ontvangen.
          </label>
        </div>
        <button
          className="btn btn--primary btn--sm form__button"
          onClick={() => handleSubmit(onSubmit)()}
          disabled={status === 'pending' || !isChecked}
        >
          {status === 'pending' ? (
            <img src={loader} width="20px" alt="loading..." />
          ) : (
            'Aanmelden voor de masterclass'
          )}
        </button>
      </div>
    </div>
  );
};

export default Form;
