import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/SEO/seo';
import { StaticImage } from 'gatsby-plugin-image';
import PFLogo from '../assets/images/webinar/profit-first-logo.svg';
import FlowThumbnail from '../assets/images/webinar/profit-first-automation.jpg';
import Form from '../components/webinar/Form';
import sprout from '../assets/images/partners/sprout.png';
import telegraaf from '../assets/images/partners/telegraaf.png';
import emerce from '../assets/images/partners/emerce.png';
import npo from '../assets/images/partners/npo.png';
import volkskrant from '../assets/images/partners/volkskrant.png';
import Alexander from '../assets/images/webinar/Alexander_Klopping.jpg';
import Ernst from '../assets/images/webinar/Ernst-Jan_Pfauth.jpg';
import DeVolkskrant from '../assets/images/webinar/De_Volkskrant.jpg';
import banner from '../assets/images/webinar/flow-cover.png';

const cardsData = [
  {
    id: 1,
    imagePath: Alexander,
    name: 'Alexander Klöpping',
    position: 'CEO Blendle/Tech journalist in ‘Een Podcast over Media’',
    quote:
      '“Dat voelt goed hè? Het idee dat dit soort dingen geautomatiseerd zijn, ik krijg zo’n ontspannenheid in mijn lijf.“',
  },
  {
    id: 2,
    imagePath: Ernst,
    name: 'Ernst-Jan Pfauth',
    position: 'CEO De Correspondent in ‘Een Podcast over Media’',
    quote: '“Het werkt op de achtergrond, je kunt erop vertrouwen, dat vind ik er fijn aan.“',
  },
  {
    id: 3,
    imagePath: DeVolkskrant,
    name: 'De Volkskrant',
    quote:
      '“Waar huishoudboekjes dagelijks of wekelijks je aandacht vragen, wil Flow Your Money dat je één keer een geldsysteem instelt zodat je er vervolgens niet meer naar hoeft om te kijken.“',
  },
];

const Masterclass = (page: PageProps) => {
  return (
    <>
      <Seo page={page} banner={banner} title="Flow Masterclass" />
      <main className="page-flow-masterclass">
        <section className="section-1">
          <div className="container">
            <div className="section-1__content">
              <img src={PFLogo} alt="flow" className="section-1__logo section-1__logo--secondary" />
              <h2 className="section-1__subtitle">
                Gratis Masterclass voor freelancers en ondernemers
              </h2>
              <h1 className="section-1__title u-text-primary">
                Profit First automatiseren met Flow
              </h1>
              <div className="section-1__text u-color-lavender ">
                Ontdek hoe je je geldstromen duidelijk inzichtelijk maakt en automatisch kan
                verdelen, zodat je langetermijndoelen haalbaar worden.
              </div>
            </div>
          </div>
        </section>
        <section className="section-2">
          <div className="container">
            <div className="section-2__content">
              <StaticImage
                className="section-2__image"
                src="../assets/images/webinar/flow-founders.jpg"
                alt="Founders"
              />
              <Form className="section-2__form" />
            </div>
          </div>
        </section>
        <section className="section-3 u-section-padding-sm">
          <div className="container">
            <div className="section-3__content">
              <img src={sprout} alt="sprout" className="section-3__img" />
              <img src={telegraaf} alt="telegraaf" className="section-3__img" />
              <img src={volkskrant} alt="volkskrant" className="section-3__img" />
              <img src={npo} alt="npo" className="section-3__img" />
              <img src={emerce} alt="emerce" className="section-3__img" />
            </div>
          </div>
        </section>
        <section className="section-4 u-section-padding-sm">
          <div className="container">
            <div className="section-4__content">
              <h2 className="section-4__title">Wat leer je tijdens deze masterclass?</h2>
              <ul className="section-4__list">
                <li className="section-4__item">Meer over Flow en Profit First Professionals</li>
                <li className="section-4__item">
                  Hoe je winst maakt bij íedere betaling die je ontvangt
                </li>
                <li className="section-4__item">
                  Hoe je zorgt dat je altijd voldoende geld hebt gereserveerd voor de fiscus, de
                  leveranciers en je eigen salaris
                </li>
                <li className="section-4__item">
                  Hoe je in één oogopslag kunt zien hoe je ervoor staat, zodat je de juiste keuzes
                  kunt maken
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="section-5 u-section-padding">
          <div className="container">
            <div className="section-5__content">
              <StaticImage
                className="section-5__image"
                src="../assets/images/webinar/daan.jpg"
                alt="Niels and Daan"
              />
              <div className="section-5__info">
                <h2 className="section-5__title">Wie geven deze masterclass?</h2>
                <div className="section-5__text">
                  <p className="section-5__paragraph">
                    De masterclass wordt gegeven door Femke Hogema en Daan van Klinken. Femke is de
                    oprichter van Profit First Professionals. Zij ondersteunt ondernemers en helpt
                    ze een financieel gezond en winstgevend bedrijf te runnen. Daarnaast is ze
                    trainer, spreker, auteur en inspirator. Ze heeft een aantal boeken geschreven,
                    waaronder "Winstgevende plannen", "De Winst Adviseur", "Ondernemen in
                    Crisistijd" en "Financiën voor ZZP'ers". Daarnaast vertaalde ze het wereld
                    bekende boek van Mike Michalowicz "Profit First" in het Nederlands.
                  </p>
                  <p className="section-5__paragraph">
                    Daan van Klinken is de oprichter van Flow, een FinTech startup. Hij helpt
                    ondernemers hun financiële automatische piloot in te richten. Door deze
                    automatische piloot kunnen ondernemers beter met hun geld omgaan en bouwen aan
                    een financieel gezonde toekomst. Daan houdt zich al jaren bezig met technologie.
                    Zo heeft hij voordat hij Flow oprichtte gewerkt aan een aantal bekende
                    producten: Buienradar app, Radio 538 app, Ziggo Horizon TV interface, Yolt van
                    ING en de Schiphol app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-6 u-section-padding-sm">
          <div className="container">
            <div className="section-6__content">
              <h2 className="section-6__title">Profit First automatiseren met Flow </h2>
              <img
                className="section-6__image"
                width={755}
                src={FlowThumbnail}
                alt="Video thumbnail"
              />
              <div className="section-6__text">
                <p className="section-6__paragraph">
                  Geld zou je leven makkelijker moeten maken, in plaats van moeilijker. Flow
                  verandert jouw relatie met geld door je direct controle te geven. Met Flow kan
                  automatisch je geld verdelen in potjes zodat je altijd, tot op de cent, weet waar
                  je financieel aan toe bent.
                </p>
                <p className="section-6__paragraph">
                  Ook Profit First werkt met dit potjesprincipe. Het is een complete methode,
                  inclusief welke rekeningen je nodig hebt en welke percentages de juiste zijn voor
                  je bedrijf. Met de methode verander je je denkwijze: inkomsten - winst = kosten.
                  Je richt je op winst en leert tegelijk je kosten te begroten.
                </p>
                <p className="section-6__paragraph">
                  Door de samenwerking tussen Flow en Profit First, hoef je het verdelen van het
                  geld niet meer handmatig te doen. Je kunt je hele Profit First methode nu
                  automatiseren met Flow. Dit is perfect voor bedrijven die geen negatieve
                  financiële verrassingen willen, maar wel grip willen hebben op hun financiën en
                  zich willen concentreren op de groei van hun bedrijf.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-7 u-section-padding-sm">
          <div className="container">
            <div className="section-7__content">
              {cardsData.map((card) => (
                <article className="card" key={card.id}>
                  <img src={card.imagePath} className="card__logo" alt="logo" />
                  <h3 className="card__name">{card.name}</h3>
                  {card.position && <div className="card__position">{card.position}</div>}
                  <p className="card__quote">{card.quote}</p>
                </article>
              ))}
            </div>
          </div>
        </section>
        <section className="section-8 u-section-padding-sm">
          <div className="container">
            <div className="section-8__content">
              <Form isHorizontal className="section-2__form" />
            </div>
          </div>
        </section>
        <section className="section-9">
          <div className="section-9__content">
            © by Flow Money Automation b.v.
            <a href="https://flowyour.money/terms-of-use" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>
            <a
              href="http://flowyour.money/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default Masterclass;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
